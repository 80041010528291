
import {App, InjectionKey, inject} from 'vue'

// 注意:
// app._contextはundocumentedなプロパティなので、不意に実装が変更される可能性がある
// https://github.com/vuejs/vue-next/blob/master/packages/runtime-core/src/apiCreateApp.ts のprovideメソッドを参照すること

export function pluginInjectApp<T>(app: App, key: InjectionKey<T>|string): T {
  const obj = app._context.provides[key as string] as T|undefined
  if(!obj) {
    throw new Error((typeof key === 'string' ? key : key.description) + ' not provided')
  }
  return obj
}

export function pluginInject<T>(key: InjectionKey<T>|string): T {
  const obj = inject(key)
  if(!obj) {
    throw new Error((typeof key === 'string' ? key : key.description) + ' not provided')
  }
  return obj
}
