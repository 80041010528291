
import {defineComponent, reactive, toRefs} from 'vue'

import {useToast}   from '~/aax/plugins/toast'
import {useGlobals} from '~/plugins/globals'

export default defineComponent({
  setup() {
    const toast   = useToast()
    const globals = useGlobals()

    const state = reactive({
      password: '',
      driverName: '',
    })

    return {
      ...toRefs(state),

      prefix: globals.prefix,

      async onLogin() {

        if(!state.password) {
          toast.show('error', 'パスワードを入れてください')
          return
        }

        if(!state.driverName) {
          toast.show('error', '担当者名を入れてください')
          return
        }

        await globals.login(state.password, state.driverName)
      },
    }
  },
})
