
import {App, InjectionKey, onBeforeUnmount} from 'vue'
import Axios, {AxiosRequestConfig, AxiosInstance} from 'axios'

import {pluginInject, pluginInjectApp} from '~/aax/libs/plugin-inject'
import Console                         from '~/aax/libs/console-override'

import {EventBusKey}     from './event-bus'
import {ToastKey}        from './toast'
import {ErrorHandlerKey} from './error-handler'

//--------------------------------------------------------------

export type AxiosDepends = ReturnType<typeof getAxiosDepends>

export type AxiosOptions = AxiosRequestConfig

export type AxiosObject = AxiosInstance & {
  clone(config?: AxiosRequestConfig): AxiosObject
  scoped(config?: AxiosRequestConfig): AxiosObject
  cancel(): void

  $request<T = any>(config: AxiosRequestConfig): Promise<T>
  $get    <T = any>(url: string, config?: AxiosRequestConfig): Promise<T>
  $delete <T = any>(url: string, config?: AxiosRequestConfig): Promise<T>
  $head   <T = any>(url: string, config?: AxiosRequestConfig): Promise<T>
  $options<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>
  $post   <T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>
  $put    <T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>
  $patch  <T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>
}

//--------------------------------------------------------------

export const AxiosKey: InjectionKey<AxiosObject> = Symbol('axios')

export function defineAxios(options: AxiosOptions) {
  return options
}

export function getAxiosDepends(app: App) {
  return {
    eventBus:     pluginInjectApp(app, EventBusKey),
    toast:        pluginInjectApp(app, ToastKey),
    errorHandler: pluginInjectApp(app, ErrorHandlerKey),
  }
}

export const AxiosPlugin = {
  install(app: App, options: AxiosOptions) {
    app.provide(AxiosKey, createAxios(getAxiosDepends(app), options))
  },
}

export function useAxios() {
  return pluginInject(AxiosKey)
}

//--------------------------------------------------------------

export const eventUnauthorized       = Symbol('axios-unauthorized')
export const eventServiceUnavailable = Symbol('axios-service-unavailable')

export function createAxios(depends: AxiosDepends, options?: AxiosOptions) {
  const {eventBus, toast, errorHandler} = depends

  errorHandler.addHandler((err, next) => {

    // nullの場合はスルー
    if(err == null) {
      return 1
    }

    // dialogなどのキャンセルの場合はスルー
    // if(err.name === 'Error' && err.message === '') {
    //   return 1
    // }
    // TODO: ここの実装は再検討

    // axiosのキャンセルの場合はスルー
    if(Axios.isCancel(err)) {
      Console.former.warn('通信がキャンセルされました')
      return 1
    }

    // axiosのエラーの場合
    if(Axios.isAxiosError(err)) {
      Console.former.error(err) // eslint-disable-line no-console

      // 接続できない場合
      if(err.response == null) {
        toast.show('error', '通信エラーが発生しました')
        return 1
      }

      // 認証無効
      if((err.response.status === 401 || err.response.status === 419) && eventBus.has(eventUnauthorized)) {
        eventBus.emit(eventUnauthorized)
        return 1
      }

      // メンテナンスモード
      // TODO: 元々Laravel に対応したもの, ここの実装は再検討が必要
      if((err.response.status === 502 || err.response.status === 503) && eventBus.has(eventServiceUnavailable)) {
        eventBus.emit(eventServiceUnavailable)
        return 1
      }

      // サーバーバリデーション違反応答の場合
      // TODO: 元々Laravel に対応したもの, ここの実装は再検討が必要
      // if(err.response.status === 422 && err.response.data != null) {
      //   if(err.response.data.errors) {
      //     return () => {
      //       onValidationFail(err.response.data.errors)
      //     }
      //   }
      //   if(err.response.data.message) {
      //     return () => {
      //       toast.show('error', err.response.data.message)
      //     }
      //   }
      // }

      // それ以外のサーバーエラーコード
      if(!import.meta.env.PROD) {
        toast.show('error',
          'サーバーエラー' + err.response.status +
          ' ' + JSON.stringify(err.response.data).substring(0, 200)
        )
        return 1
      }

      toast.show('error', 'サーバーエラー' + err.response.status + 'が発生しました')
      return 1
    }

    next()
  })

  // オブジェクト作成
  const createObject = (baseConfig: AxiosRequestConfig) => {
    const obj = Axios.create(baseConfig) as AxiosObject

    // クローンを作成
    obj.clone = (config?: AxiosRequestConfig) => createObject({...baseConfig, ...config})

    // コンポーネント寿命スコープをもつクローンを作成
    obj.scoped = (config?: AxiosRequestConfig) => {
      const obj = createObject({...baseConfig, ...config})
      onBeforeUnmount(() => {obj.cancel()})
      return obj
    }

    // キャンセル
    let cancelTokenSource = Axios.CancelToken.source()

    obj.interceptors.request.use(config => {
      if(!config.cancelToken) {
        config.cancelToken = cancelTokenSource.token
      }
      return config
    })

    obj.cancel = () => {
      cancelTokenSource.cancel()
      cancelTokenSource = Axios.CancelToken.source()
    }

    // dataを直接返すリクエスト
    for(const name of ['request', 'get', 'delete', 'head', 'options', 'post', 'put', 'patch']) {
      (obj as any)['$' + name] = async (...args: any[]) => (await (obj as any)[name](...args)).data
    }

    return obj
  }

  return createObject({
    headers: ['delete', 'post', 'put', 'patch'].reduce((o, v) => ({...o, [v]: {'X-Requested-With': 'XMLHttpRequest'}}), {}),

    ...options,
  })
}
