
import {App, InjectionKey} from 'vue'

import {pluginInject} from '~/aax/libs/plugin-inject'

//--------------------------------------------------------------

export type ToastDepends = ReturnType<typeof getToastDepends>

export type ToastOptions = {}

export type ToastObject = ReturnType<typeof createToast>

//--------------------------------------------------------------

export const ToastKey: InjectionKey<ToastObject> = Symbol('toast')

export function defineToast(options: ToastOptions) {
  return options
}

export function getToastDepends(app: App) {
  return {}
}

export const ToastPlugin = {
  install(app: App, options: ToastOptions) {
    app.provide(ToastKey, createToast(getToastDepends(app), options))
  },
}

export function useToast() {
  return pluginInject(ToastKey)
}

//--------------------------------------------------------------

export function createToast(depends?: ToastDepends, options?: ToastOptions) {
  return {
    show(severity: string, ...args: any[]) {},
  }
}
