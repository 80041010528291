
import {createRouter, createWebHistory} from 'vue-router'

import MeterDashboard from './views/meter-dashboard.vue'
import MeterRecord    from './views/meter-record.vue'

export default createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [

    {
      path: '/meter',
      name: 'meter-dashboard',
      component: MeterDashboard,
    },

    {
      path: '/meter/record',
      name: 'meter-record',
      component: MeterRecord,
    },

    {
      path: '/:catchAll(.*)',
      redirect: '/meter',
    },
  ],
})
