
import {createApp} from 'vue'

import App    from './App.vue'
import router from './router'

import IButton from './components/IButton.vue'
import IInput  from './components/IInput.vue'

import {EventBusPlugin}     from './aax/plugins/event-bus'
import {ToastPlugin}        from './aax/plugins/toast__dk-toast'
import {ReportPlugin}       from './aax/plugins/report__post'
import {JsHooksPlugin}      from './aax/plugins/js-hooks'
import {ErrorHandlerPlugin} from './aax/plugins/error-handler'
import {AxiosPlugin}        from './aax/plugins/axios'

import {loadGlobalsPlugin} from './plugins/globals'

;(async () => {

  const app = createApp(App)
  .component('IButton', IButton)
  .component('IInput', IInput)
  .use(EventBusPlugin)
  .use(ToastPlugin)
  .use(ReportPlugin)
  .use(JsHooksPlugin)
  .use(ErrorHandlerPlugin)
  .use(AxiosPlugin, {timeout: 10000})

  const GlobalsPlugin = await loadGlobalsPlugin(app, 'meter')

  app
  .use(GlobalsPlugin)
  .use(router)
  .mount('#app')

})()
