<template lang="pug">
.prevent-sleep(
  v-if="enable"
  @mousedown="onClick"
)
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue'
import NoSleep from 'nosleep.js'

export default defineComponent({
  setup() {
    const enable = ref(true)

    let nosleep

    return {
      enable,

      async onClick() {
        enable.value = false

        nosleep = await new NoSleep
        nosleep.enable()
      },
    }
  },
})
</script>

<style lang="scss">
.prevent-sleep {
  background-color: black;
  bottom: 0;
  left: 0;
  opacity: .2;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000000;
}
</style>
