<template lang="pug">
input(
  :value="value"
  @input="onInput"
  @change="onChange"
  @keydown="onKeydown"
)
</template>

<script lang="ts">
import {defineComponent, computed} from 'vue'

export default defineComponent({

  props: {
    modelValue: String,
    modelModifiers: Object,
  },

  emits: [
    'update:modelValue',
  ],

  setup(props, {emit}) {

    const update = (ev: InputEvent) => {
      const el = ev.target
      if(el instanceof HTMLInputElement) {
        emit('update:modelValue', el.value)
      }
    }

    return {
      value: computed(() => props.modelValue),

      onInput(ev: InputEvent) {
        if(!props.modelModifiers?.lazy) {
          update(ev)
        }
      },

      onChange(ev: InputEvent) {
        if(props.modelModifiers?.lazy) {
          update(ev)
        }
      },

      onKeydown(ev: KeyboardEvent) {
        if(ev.key === 'Enter') {
          ev.preventDefault()
          const el = ev.target
          if(el instanceof HTMLInputElement) {
            el.blur()
          }
        }
      },
    }
  },
})
</script>
