
import {defineComponent} from 'vue'
import {useRouter} from "vue-router"

import {useToast}        from '~/aax/plugins/toast'
import {useAxios}        from '~/aax/plugins/axios'
import {useGlobals}      from '~/plugins/globals'
import {useMeterContext} from '~/providers/meter-context'

export default defineComponent({
  setup() {
    const router   = useRouter()
    const toast    = useToast()
    const http     = useAxios().scoped()
    const globals  = useGlobals()
    const meterCtx = useMeterContext()

    if(meterCtx.isInitial.value || meterCtx.isRunning.value) {
      router.replace('/meter/dashboard')
    }

    return {
      prefix:        globals.prefix,
      driverName:    globals.driverName,

      startDateTime: meterCtx.startDateTime,
      endDateTime:   meterCtx.stopDateTime,
      runningTime:   meterCtx.runningTime,
      fare:          meterCtx.fare,
      discount:      meterCtx.discountLabel,
      customerName:  meterCtx.customerName,

      async toBack() {
        await router.replace({name: 'meter-dashboard'})
      },

      async onSave() {
        if(confirm('乗車記録を送信して、現在の状態をリセットします。よろしいですか？')) {

          await http.post('/_/api/meter/records', {
            startDateTime: meterCtx.startDateTime.value,
            endDateTime:   meterCtx.stopDateTime.value,
            runningTime:   meterCtx.runningTime.value,
            fare:          meterCtx.fare.value,
            discount:      meterCtx.discountLabel.value,
            customerName:  meterCtx.customerName.value,
            driverName:    globals.driverName.value,
          })

          meterCtx.resetMeter()

          await router.replace({name: 'meter-dashboard'})

          toast.show('success', '記録しました')
        }
      },

      async onLogout() {
        if(confirm('ログアウトします。よろしいですか？')) {
          await globals.logout()
        }
      },
    }
  },
})
