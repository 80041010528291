
import {defineComponent, onBeforeUnmount} from 'vue'
import {useRouter} from "vue-router"

import {useGlobals}      from '~/plugins/globals'
import {useMeterContext} from '~/providers/meter-context'

export default defineComponent({
  setup() {
    const router   = useRouter()
    const globals  = useGlobals()
    const meterCtx = useMeterContext()

    onBeforeUnmount(() => {
      meterCtx.stopMeter()
    })

    return {
      prefix:      globals.prefix,
      driverName:  globals.driverName,

      runningTime: meterCtx.runningTime,
      fare:        meterCtx.fare,
      isInitial:   meterCtx.isInitial,
      isRunning:   meterCtx.isRunning,
      isSurcharge: meterCtx.isSurcharge,

      onStart:     meterCtx.startMeter,
      onStop:      meterCtx.stopMeter,

      async onReset() {
        if(confirm('現在の状態をリセットします。よろしいですか？')) {
          meterCtx.resetMeter()
        }
      },

      async onLogout() {
        if(confirm('ログアウトします。よろしいですか？')) {
          await globals.logout()
        }
      },

      async toRecord() {
        await router.replace({name: 'meter-record'})
      },

      onDiscount: meterCtx.toggleDiscount,
      isDiscount: meterCtx.isDiscount,

      async onDebug() {
        const datetime = prompt('datetime')
        if(datetime) meterCtx.onDebug(datetime)
      },
    }
  },
})
