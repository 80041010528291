
import {App} from 'vue'

import {ReportOptions as ParentOptions, ReportKey, getReportDepends, useReport, createReport as createParent} from './report'

//--------------------------------------------------------------

export type ReportDepends = ReturnType<typeof getReportDepends>

export type ReportOptions = ParentOptions & {
  postUrl?: string
}

export type ReportObject = ReturnType<typeof createReport>

//--------------------------------------------------------------

export {ReportKey}

export function defineReport(options: ReportOptions) {
  return options
}

export {getReportDepends}

export const ReportPlugin = {
  install(app: App, options: ReportOptions) {
    app.provide(ReportKey, createReport(getReportDepends(app), options))
  },
}

export {useReport}

//--------------------------------------------------------------

export function createReport(depends: ReportDepends, options?: ReportOptions) {

  if(!import.meta.env.PROD) {
    return createParent(depends, options)
  }

  const obj = {
    postUrl: options?.postUrl ?? '/_/api/report',

    async submit(severity: string, ...args: any[]) {
      try {
        args = args.map(arg => {
          if(arg instanceof Error) {
            return {
              error: arg.toString(),
              stackRaw: arg.stack,
            }
          }
          return arg?.toString?.() ?? 'unknown'
        })

        await fetch(this.postUrl, {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            time: (new Date).toJSON(),
            url: location.href,
            data: args,
          }),
        })
      }
      catch(err) {}
    },
  }

  return obj
}
