
import {App} from 'vue'

import DKToast, {Toast} from 'vue-dk-toast'

import {ToastOptions, ToastKey, defineToast, getToastDepends as getParentDepends, useToast} from './toast'

//--------------------------------------------------------------

export type ToastDepends = ReturnType<typeof getToastDepends>

export type {ToastOptions}

export type ToastObject = ReturnType<typeof createToast>

//--------------------------------------------------------------

export {ToastKey}

export {defineToast}

export function getToastDepends(app: App) {
  return {
    ...getParentDepends(app),
    app,
  }
}

export const ToastPlugin = {
  install(app: App, options: ToastOptions) {
    app.provide(ToastKey, createToast(getToastDepends(app), options))
  },
}

export {useToast}

//--------------------------------------------------------------

type ToastOptions = Parameters<Toast>[1]

export function createToast(depends: ToastDepends, options?: ToastOptions) {
  const {app} = depends

  app.use(DKToast, {duration: 3000, positionX: 'center', positionY: 'top'})
  const toast = app.config.globalProperties.$toast as Toast

  const show = (args: any[], opts?: ToastOptions) => {
    const text = args.map(arg => arg?.toString?.() ?? 'unknown').join(' ')
    toast(text, opts)
  }

  return {
    show(severity: string, ...args: any[]) {
      switch(severity) {

        default:
        case 'error':
        case 'warn':
          show(args, {type: 'error'})
          break

        case 'info':
          show(args)
          break

        case 'success':
          show(args, {type: 'success'})
          break

        case 'update':
          show(args, {type: 'passive', duration: 1000})
          break
      }
    },
  }
}
