<template lang="pug">
LoginScreen
  template(v-slot:default)
    router-view(v-slot="{Component, route}")
      transition(name="fade")
        component.view-container(:is="Component" :key="route.fullpath")

  template(v-slot:login)
    MeterLogin

PreventSleep
VersionMark
</template>

<script lang="ts">
import {defineComponent} from 'vue'

import LoginScreen  from './components/LoginScreen.vue'
import PreventSleep from './components/PreventSleep.vue'
import VersionMark  from './components/VersionMark.vue'
import MeterLogin   from './views/meter-login.vue'

import {useGlobals}          from './plugins/globals'
import {provideMeterContext} from './providers/meter-context'

export default defineComponent({
  components: {LoginScreen, PreventSleep, VersionMark, MeterLogin},

  setup() {
    provideMeterContext(useGlobals())
  },
})
</script>

<style src="./views/styles/global-meter.scss"></style>

<style lang="scss">
.view-container {
  min-height: 100vh;
  width: 100%;
}

.fade-enter-active,
.fade-leave-active {
  position: absolute;
  transition: opacity .3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}
</style>
