
import {App, InjectionKey} from 'vue'

import {pluginInject} from '~/aax/libs/plugin-inject'

//--------------------------------------------------------------

export type EventBusDepends = ReturnType<typeof getEventBusDepends>

export type EventBusOptions = {}

export type EventBusObject = ReturnType<typeof createEventBus>

//--------------------------------------------------------------

export const EventBusKey: InjectionKey<EventBusObject> = Symbol('event-bus')

export function defineEventBus(options: EventBusOptions) {
  return options
}

export function getEventBusDepends(app: App) {
  return {}
}

export const EventBusPlugin = {
  install(app: App, options: EventBusOptions) {
    app.provide(EventBusKey, createEventBus(getEventBusDepends(app), options))
  },
}

export function useEventBus() {
  return pluginInject(EventBusKey)
}

//--------------------------------------------------------------

type Key = string|symbol

export function createEventBus(depends?: EventBusDepends, options?: EventBusOptions) {
  const events = {} as any

  return {

    on(key: Key|Key[], fn: Function) {

      const on = (key: Key, fn: Function) => {
        const event = events[key] as Function[]|undefined
        if(!event) events[key] = [fn]
        else event.push(fn)
      }

      if(!Array.isArray(key)) on(key, fn)
      else {
        for(const key_ of key) {
          on(key_, fn)
        }
      }
      return this
    },

    once(key: Key, fn: Function) {
      const fn_ = (...args: any[]) => {
        this.off(key, fn_)
        fn(...args)
      }
      return this.on(key, fn_)
    },

    off(key?: Key|Key[], fn?: Function) {

      const off = (key: Key, fn?: Function) => {
        const event = events[key] as Function[]|undefined
        if(event) {
          if(!fn) delete events[key]
          else {
            const event_ = event.filter(fn_ => fn_ !== fn)
            if(event_.length === 0) delete events[key]
            else events[key] = event_
          }
        }
      }

      if(!key) key = Object.keys(events)
      if(!Array.isArray(key)) off(key, fn)
      else {
        for(const key_ of key) {
          off(key_, fn)
        }
      }
      return this
    },

    emit(key: Key, ...args: any[]) {
      const event = events[key] as Function[]|undefined
      if(event) {
        for(const fn of event) {
          fn(...args)
        }
      }
      return this
    },

    async emitAsync(key: Key, ...args: any[]) {
      const event = events[key] as Function[]|undefined
      if(event) await Promise.all(event.map(fn => fn(...args)))
      return this
    },

    has(key: Key) {
      const event = events[key] as Function[]|undefined
      return !!event?.length
    },
  }
}
