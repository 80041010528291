
export function formatTime(seconds: number) {
  return Math.floor(seconds / 60).toString() + ':' +
         Math.floor(seconds % 60).toString().padStart(2, '0')
}

export function formatDate(timestampMilliseconds: number) {
  if(timestampMilliseconds === 0) {
    return ''
  }
  const dateObj = new Date(timestampMilliseconds)
  return  dateObj.getFullYear()   + '/' +
         (dateObj.getMonth() + 1) + '/' +
          dateObj.getDate()       + ' ' +
          dateObj.getHours()      + ':' +
          dateObj.getMinutes().toString().padStart(2, '0')
}
