
import {App} from 'vue'

import {pluginInjectApp} from '~/aax/libs/plugin-inject'
import Console           from '~/aax/libs/console-override'

import {ToastKey}  from './toast'
import {ReportKey} from './report'

//--------------------------------------------------------------

export type JsHooksOptions = {
  errorEvent?(ev: ErrorEvent): any
  unhandledrejectionEvent?(ev: PromiseRejectionEvent): any
  rejectionhandledEvent?(ev: PromiseRejectionEvent): any
}

//--------------------------------------------------------------

export function defineJsHooks(options: JsHooksOptions) {
  return options
}

export function getJsHooksDepends(app: App) {
  return {
    toast:  pluginInjectApp(app, ToastKey),
    report: pluginInjectApp(app, ReportKey),
  }
}

export const JsHooksPlugin = {
  install(app: App, options: JsHooksOptions) {
    const {toast, report} = getJsHooksDepends(app)

    Console.override('error', (...args) => {
      Console.former.error(...args)
      report.submit('error', ...args)
    })

    Console.override('warn', (...args) => {
      Console.former.warn(...args)
      report.submit('warn', ...args)
    })

    const hooks = {
      errorEvent(ev: ErrorEvent) {
        report.submit('error', 'ErrorEvent', ev.message, ev.filename, ev.lineno, ev.colno, ev.error)
      },

      unhandledrejectionEvent(ev: PromiseRejectionEvent) {
        report.submit('error', 'unhandledrejection', ev.reason)
      },

      rejectionhandledEvent(ev: PromiseRejectionEvent) {
        report.submit('error', 'rejectionhandled', ev.reason)
      },

      ...options,
    }

    window.addEventListener('error',              hooks.errorEvent)
    window.addEventListener('unhandledrejection', hooks.unhandledrejectionEvent)
    window.addEventListener('rejectionhandled',   hooks.rejectionhandledEvent)

    // 開発環境のみ: vue vite hmr 更新を通知
    if(import.meta.env.DEV) {
      Console.override('log', (...args) => {
        Console.former.log(...args)
        if(typeof args[0] === 'string' && args[0].startsWith('[vite] hot updated:')) {
          toast.show('update', '更新しました')
        }
      })
    }
  },
}
