
import {App, InjectionKey} from 'vue'

import {pluginInject, pluginInjectApp} from '~/aax/libs/plugin-inject'

import {ToastKey} from './toast'

//--------------------------------------------------------------

export type ReportDepends = ReturnType<typeof getReportDepends>

export type ReportOptions = {}

export type ReportObject = ReturnType<typeof createReport>

//--------------------------------------------------------------

export const ReportKey: InjectionKey<ReportObject> = Symbol('report')

export function defineReport(options: ReportOptions) {
  return options
}

export function getReportDepends(app: App) {
  return {
    toast: pluginInjectApp(app, ToastKey),
  }
}

export const ReportPlugin = {
  install(app: App, options: ReportOptions) {
    app.provide(ReportKey, createReport(getReportDepends(app), options))
  },
}

export function useReport() {
  return pluginInject(ReportKey)
}

//--------------------------------------------------------------

export function createReport(depends: ReportDepends, options?: ReportOptions) {
  const {toast} = depends

  return {
    async submit(severity: string, ...args: any[]) {
      toast.show(severity, ...args)
    },
  }
}
