<template lang="pug">
slot(v-if="auth" name="default")
slot(v-else name="login")
</template>

<script lang="ts">
import {defineComponent} from 'vue'

import {useGlobals} from '~/plugins/globals'

export default defineComponent({
  setup() {
    const globals = useGlobals()

    return {
      auth: globals.auth,
    }
  },
})
</script>
