
import {App, InjectionKey, reactive, computed} from 'vue'

import {pluginInject, pluginInjectApp} from '~/aax/libs/plugin-inject'
import {merge, assign}                 from '~/aax/libs/object'
import {sleep}                         from '~/aax/libs/promise-sleep'

import {EventBusKey}                 from '~/aax/plugins/event-bus'
import {ToastKey}                    from '~/aax/plugins/toast'
import {AxiosKey, eventUnauthorized} from '~/aax/plugins/axios'

//--------------------------------------------------------------

export type GlobalsDepends = ReturnType<typeof getGlobalsDepends>

export type GlobalsOptions = 'admin'|'meter'

export type GlobalsObject = ReturnType<typeof createGlobals>

//--------------------------------------------------------------

export const GlobalsKey: InjectionKey<GlobalsObject> = Symbol('globals')

export function defineGlobals(options: GlobalsOptions) {
  return options
}

export function getGlobalsDepends(app: App) {
  return {
    eventBus: pluginInjectApp(app, EventBusKey),
    toast:    pluginInjectApp(app, ToastKey),
    axios:    pluginInjectApp(app, AxiosKey),
  }
}

export async function loadGlobalsPlugin(app: App, options: GlobalsOptions) {
  const obj = createGlobals(getGlobalsDepends(app), options)

  for(;;) {
    try {
      await obj.load()
      break
    }
    catch(e) {
      await sleep(1000)
    }
  }

  return {
    install(app: App) {
      app.provide(GlobalsKey, obj)
    },
  }
}

export function useGlobals() {
  return pluginInject(GlobalsKey)
}

//--------------------------------------------------------------

export const GlobalDefaultPayload = {
  version: 'N/A',
  prefix: '',
  auth: null,
  driverName: '',
  prefs: {
    unitPrice:  320,
    unitSecond: 300,
  },
}

export function createGlobals(depends: GlobalsDepends, options: GlobalsOptions) {
  const {eventBus, toast, axios} = depends

  const globalsUrl = '/_/api/' + options + '/globals'
  const loginUrl   = '/_/api/' + options + '/login'
  const logoutUrl  = '/_/api/' + options + '/logout'

  let payload = reactive(merge(GlobalDefaultPayload))

  const obj = {

    version:    computed(() => payload.version),
    prefix:     computed(() => payload.prefix),
    auth:       computed(() => payload.auth),
    driverName: computed(() => payload.driverName),
    unitPrice:  computed(() => payload.prefs.unitPrice),
    unitSecond: computed(() => payload.prefs.unitSecond),

    async load() {
      assign(payload, GlobalDefaultPayload, await axios.$get(globalsUrl))
    },

    async login(password: string, driverName: string = '') {
      try {
        await axios.put(loginUrl, {password, driverName})
        await this.load()
      }
      catch(e) {
        if(e?.response?.status === 401) {
          toast.show('error', 'パスワードが一致しません')
        }
        else {
          throw e
        }
      }
    },

    async logout() {
      await axios.put(logoutUrl)
      await this.load()
    },
  }

  eventBus.on(eventUnauthorized, async () => {
    try {
      toast.show('error', 'ログアウトしました')
      await obj.load()
    }
    catch(err) {
      payload.auth = null
    }
  })

  return obj
}
