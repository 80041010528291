
import {InjectionKey, provide, inject, reactive, toRef, watch, computed} from 'vue'
import {assign} from '~/aax/libs/object'
import {formatTime, formatDate} from '~/libs/date'
import {GlobalsObject} from '~/plugins/globals'

export type MeterContextObject = ReturnType<typeof createMeterContext>

export const MeterContextKey: InjectionKey<MeterContextObject> = Symbol('meter-context')

export function createMeterContext(globals: GlobalsObject) {

  const discounts = [
    {label: 'なし',                   fn: (fare: number) => fare},
    {label: '身体障害者割引',         fn: (fare: number) => Math.floor(fare * .9)},
    {label: '知的障害者割引',         fn: (fare: number) => Math.floor(fare * .9)},
    {label: '介護保険適用認定者割引', fn: (fare: number) => Math.max(0, fare <= 1000 ? fare - 100 : fare - 900)},
  ]

  const initState = {
    timerId:      <number|undefined>undefined,
    startTime:    0,
    updateTime:   0,
    totalMs:      0,
    chargedMs:    0,
    rawFare:      0,
    surcharge:    0,
    discountType: 0,
    customerName: '',
  }

  const state = reactive({...initState})

  const updateRawFare = (now: number) => {
    let elapsedMs = now - state.updateTime

    do {
      if(elapsedMs < state.chargedMs) {
        state.updateTime = now
        state.totalMs += elapsedMs
        state.chargedMs -= elapsedMs
        break
      }

      state.updateTime += state.chargedMs
      state.totalMs += state.chargedMs
      elapsedMs -= state.chargedMs

      state.chargedMs = globals.unitSecond.value * 1000

      // 単位時間の価格を計算する
      // 22:00〜05:00は2割増で考える

      let time = state.updateTime
      const timeEnd = time + state.chargedMs

      const multipliers = [1, 1.2]
      const results = multipliers.map(() => 0)

      const getNext = (time: number) => {
        const obj = new Date(time)
        const hours = obj.getHours()

        if(hours < 5) {
          obj.setHours(5, 0, 0, 0)
          return {surcharge: 1, next: obj.getTime()}
        }

        if(hours < 22) {
          obj.setHours(22, 0, 0, 0)
          return {surcharge: 0, next: obj.getTime()}
        }

        obj.setDate(obj.getDate() + 1)
        obj.setHours(5, 0, 0, 0)
        return {surcharge: 1, next: obj.getTime()}
      }

      while(time < timeEnd) {
        const {surcharge, next} = getNext(time)

        if(surcharge) {
          state.surcharge = surcharge
        }

        if(timeEnd < next) {
          results[surcharge] += timeEnd - time
          break
        }

        results[surcharge] += next - time
        time = next
      }

      const sum = results.reduce((a, v) => a + v, 0)
      const multiplier = sum === 0 ? 0 :
        results.reduce((a, v, i) => a + v * multipliers[i], 0) / sum

      state.rawFare += Math.ceil(globals.unitPrice.value * multiplier)

    } while(0 < elapsedMs)
  }

  const startTimer = () => {
    state.timerId = setInterval(() => {
      updateRawFare(Date.now())
    }, 200)
  }

  // localStorageにコンテキストを保存/復元する

  try {
    assign(state, JSON.parse(localStorage.getItem('meter-context') ?? '{}'))
  }
  catch(err) {console.error(err)}

  watch(state, value => {
    try {
      localStorage.setItem('meter-context', JSON.stringify(value))
    }
    catch(err) {console.error(err)}
  })

  if(state.timerId) {
    startTimer()
  }

  //

  return {

    runningTime: computed(() => formatTime(Math.ceil(state.totalMs / 1000))),
    fare:        computed(() => discounts[state.discountType].fn(state.rawFare)),
    isInitial:   computed(() => state.startTime === 0),
    isRunning:   computed(() => state.timerId !== undefined),
    isSurcharge: computed(() => state.surcharge !== 0),
    isDiscount:  (type: number) => type === state.discountType,

    startDateTime: computed(() => formatDate(state.startTime)),
    stopDateTime:  computed(() => formatDate(state.updateTime)),
    discountLabel: computed(() => discounts[state.discountType].label),
    customerName:  toRef(state, 'customerName'),

    startMeter() {
      if(state.timerId === undefined) {
        startTimer()

        const now = Date.now()
        if(state.startTime === 0) {
          state.startTime = now
        }
        state.updateTime = now
        updateRawFare(now)
      }
    },

    stopMeter() {
      if(state.timerId !== undefined) {
        clearInterval(state.timerId)
        state.timerId = undefined

        updateRawFare(Date.now())
      }
    },

    resetMeter() {
      clearInterval(state.timerId)

      Object.assign(state, initState)
    },

    toggleDiscount(type: number) {
      state.discountType = state.discountType === type ? 0 : type
    },

    onDebug(datetime: string) {
      const obj = new Date(datetime)
      if(obj instanceof Date && isFinite(obj.getTime())) {
        console.log('💥DEBUG', obj)
        const now = obj.getTime()
        if(state.startTime === 0) state.startTime = now
        if(state.updateTime === 0) state.updateTime = now
        updateRawFare(now)
      }
    },
  }
}

export function provideMeterContext(globals: GlobalsObject) {
  const obj = createMeterContext(globals)
  provide(MeterContextKey, obj)
  return obj
}

export function useMeterContext() {
  const obj = inject(MeterContextKey)
  if(!obj) throw new Error('meter-context not provided')
  return obj
}
