
export function castTo(template: any, value: any) {
  if(template === value) return value

  switch(typeof template) {
    case 'boolean': return Boolean(value)
    case 'number':  return Number(value)
    case 'bigint':  return BigInt(value)
    case 'string':  return String(value)
    default:        return value
  }
}

export function assign<TYPE>(object: TYPE, ...sources: any[]) {
  for(const [key, value] of Object.entries(object)) {
    if(value !== null && typeof value === 'object') {
      (object as any)[key] = assign(
        value,
        ...sources.map(source => key in source ? source[key] : {}),
      )
      continue
    }
    (object as any)[key] = castTo(
      value,
      sources.reduce((value, source) => key in source ? source[key] : value, value),
    )
  }
  return object
}

export function merge<TYPE>(object: TYPE, ...sources: any[]) {
  const output: any = {}
  for(const [key, value] of Object.entries(object)) {
    if(value !== null && typeof value === 'object') {
      output[key] = merge(
        value,
        ...sources.map(source => key in source ? source[key] : {}),
      )
      continue
    }
    output[key] = castTo(
      value,
      sources.reduce((value, source) => key in source ? source[key] : value, value),
    )
  }
  return output as TYPE
}
