<template lang="pug">
.version-mark version {{version}}
</template>

<script lang="ts">
import {defineComponent} from 'vue'

import {useGlobals} from '~/plugins/globals'

export default defineComponent({
  setup() {
    const globals = useGlobals()

    return {
      version: globals.version,
    }
  },
})
</script>

<style lang="sass">
.version-mark
  bottom: 2px
  color: gray
  font-size: 10px
  pointer-events: none
  position: fixed
  right: 4px
  user-select: none
  z-index: 1000000
</style>
