<template lang="pug">
button(
  type="button"
  @mousedown.left="onClick"
)
  slot
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import {sleep} from '~/aax/libs/promise-sleep'

export default defineComponent({

  emits: [
    'click',
  ],

  setup(props, {emit}) {

    return {
      async onClick() {

        // これを入れないとinputのchangeイベントなどが拾えない
        await sleep(0)

        emit('click')
      },
    }
  },
})
</script>
